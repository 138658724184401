/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
import { Button, Grid, Skeleton, Typography } from '@mui/material';
import PT from 'prop-types';
import { List as ListIcon } from '@mui/icons-material';
import { TreeView } from '@mui/lab';
import { useParams, useNavigate, createSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import EmptyState from 'components/empty-state';
import { useContext } from 'react';
import { GlobalTypesContext, AssetContext } from 'context';
import { useTranslation } from 'react-i18next';
import useAssets from './hooks/use-assets';
import TreeItems from './tree-items';

const Assets = ({
  assetNodes,
  loading,
  isProjectAssetHierarchy,
  expanded,
  setExpanded,
  dataSet,
  selectedAssets,
  updateSelection,
  onAddAssetClick,
  onEditClick,
  onClearClick,
  onDoneClick,
  textFieldError,
  onRemoveAssetClick,
}) => {
  const { assetId } = useParams();
  const { t } = useTranslation(['common']);
  const { onNodeSelect, onNodeToggle, selectedNode } = useAssets(
    assetNodes,
    isProjectAssetHierarchy,
    expanded,
    setExpanded,
    dataSet
  );
  const { assetTypeTrees } = useContext(GlobalTypesContext);
  const { assetsLoading } = useContext(AssetContext);
  const hierarchy = isProjectAssetHierarchy ? assetNodes : assetTypeTrees;

  const navigate = useNavigate();

  const paramsValues = {};
  paramsValues.order = 'asc';
  paramsValues.sort_by = 'parameter_type_name';

  return (
    <>
      {!dataSet && (
        <Button
          variant="text"
          aria-label="project-parameters-button"
          sx={{
            justifyContent: 'flex-start',
            textTransform: 'none',
            paddingLeft: theme => theme.spacing(2.5),
            paddingButton: 0,
            '& .MuiTouchRipple-child': {
              left: theme => theme.spacing(-12.5),
            },
          }}
          onClick={() => {
            paramsValues.project_parameter = true;
            navigate({
              pathname: `parameters`,
              search: `?${createSearchParams(paramsValues)}`,
            });
          }}
        >
          <Typography variant="body2">{t('common:project')}</Typography>
        </Button>
      )}
      <Grid
        aria-label="asset-tree-navigation"
        sx={{
          overflow: 'auto',
          height: '100%',
        }}
      >
        <TreeView
          onNodeToggle={onNodeToggle}
          onNodeSelect={onNodeSelect}
          selected={assetId || null}
          expanded={expanded || []}
        >
          {(loading || assetsLoading) && isEmpty(hierarchy) ? (
            [...Array(1).keys()].map(load => (
              <Skeleton
                aria-label="loading"
                key={load}
                width="100%"
                height="30px"
              />
            ))
          ) : !loading && isEmpty(hierarchy) ? (
            <EmptyState
              Icon={ListIcon}
              title="parameters:noAssets"
              subtitle="parameters:subtitleAsset"
            />
          ) : (
            hierarchy.map(node => {
              return (
                <TreeItems
                  isHierarchy={isProjectAssetHierarchy}
                  node={node}
                  key={node.id}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  dataSet={dataSet}
                  updateSelection={updateSelection}
                  selectedAssets={selectedAssets}
                  selectedNode={selectedNode}
                  onAddAssetClick={onAddAssetClick}
                  onEditClick={onEditClick}
                  onClearClick={onClearClick}
                  onDoneClick={onDoneClick}
                  textFieldError={textFieldError}
                  onRemoveAssetClick={onRemoveAssetClick}
                />
              );
            })
          )}
        </TreeView>
      </Grid>
    </>
  );
};
Assets.propTypes = {
  loading: PT.bool,
  isProjectAssetHierarchy: PT.bool,
  assetNodes: PT.arrayOf(
    PT.shape({
      id: PT.string,
      name: PT.string,
      isAssetInstance: PT.bool,
      children: PT.array,
    })
  ),
  dataSet: PT.bool,
  expanded: PT.arrayOf(PT.string).isRequired,
  setExpanded: PT.func.isRequired,
  onAddAssetClick: PT.func,
  onEditClick: PT.func,
  updateSelection: PT.func,
  selectedAssets: PT.arrayOf(PT.oneOfType([PT.string, PT.shape({})])),
  onClearClick: PT.func,
  onDoneClick: PT.func,
  textFieldError: PT.string,
  onRemoveAssetClick: PT.func,
};

Assets.defaultProps = {
  isProjectAssetHierarchy: true,
  loading: false,
  dataSet: false,
  assetNodes: [],
  onAddAssetClick: () => {},
  onEditClick: () => {},
  onClearClick: () => {},
  onDoneClick: () => {},
  updateSelection: () => {},
  selectedAssets: [],
  textFieldError: '',
  onRemoveAssetClick: () => {},
};
export default Assets;
