import { useContext, useState } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Clear as ClearIcon } from '@mui/icons-material';
import { uniqBy } from 'lodash';
import { GlobalTypesContext } from 'context';
import StatusCircle from 'components/status-circle';
import TagChip from 'components/chips/tag-chip';
import { Checkbox } from 'components/pickers/final-form-fields';

export default values => {
  const { t } = useTranslation(['common', 'parameters']);
  const { allTagsAndTypes, allDisciplines } = useContext(GlobalTypesContext);
  const [tags, setTags] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const allTags = allTagsAndTypes.map(tagType => tagType.tags).flat();

  const addTagsToSelectedParameters = (currentFields, fields) => {
    currentFields.forEach((field, i) => {
      if (field.checked) {
        let uniqTags = [...tags];
        let uniqDisciplines = [...disciplines];
        if (field?.tags) {
          uniqTags = uniqBy([...tags, ...field?.tags], 'id');
        }
        if (field?.disciplines) {
          uniqDisciplines = uniqBy(
            [...disciplines, ...field?.disciplines],
            'id'
          );
        }
        fields.update(i, {
          ...field,
          tags: uniqTags,
          disciplines: uniqDisciplines,
        });
      }
    });
    setDisciplines([]);
    setTags([]);
  };

  const formatHeaders = fields => [
    {
      key: 'select',
      label: (
        <FormControlLabel
          control={
            <MuiCheckbox
              data-testid="check-all"
              checked={values.combinedParams.every(param => param.checked)}
              indeterminate={
                !values.combinedParams.every(param => param.checked)
              }
              onChange={e => {
                values.combinedParams.forEach((field, i) => {
                  fields.update(i, { ...field, checked: e.target.checked });
                });
              }}
            />
          }
        />
      ),
      paddingLeft: 10,
      width: 30,
    },
    {
      key: 'status',
      label: t('common:dataDrawer.status.title'),
      width: 100,
    },

    {
      key: 'parameter',
      label: t('common:parameterType'),
      width: 200,
    },
    {
      key: 'tags',
      label: t('parameters:tags'),
      paddingLeft: 10,
    },
  ];

  const formatFields = (currentFields, fields) => {
    return fields.map((field, i) => ({
      key: currentFields[i].id
        ? `row-${currentFields[i].id}`
        : `row-${currentFields[i].name}-forImport`,
      select: (
        <Checkbox
          required={false}
          name={`${field}.checked`}
          options={{ label: '', value: true }}
          checked={currentFields[i].checked}
        />
      ),
      status: (
        <StatusCircle
          status={
            currentFields[i].value || currentFields[i].value === false
              ? 'answered'
              : currentFields[i].status
          }
        />
      ),
      parameter: currentFields[i].name,
      assetTypeName: currentFields[i]?.assetTypeName,
      tags: (
        <>
          <FieldArray name={`${field}.tags`} aria-label="add-tags">
            {({ fields: tagFields }) => {
              const currentTags = tagFields.value;
              return tagFields.map((val, index) => (
                <Field
                  name={val}
                  key={currentTags[index].id}
                  onDelete={() => tagFields.remove(index)}
                  render={props => (
                    <TagChip
                      name={props.input.name}
                      label={currentTags[index].name}
                      tagType={currentTags[index].tagType?.name}
                      onDelete={props.onDelete}
                      deleteIcon={<ClearIcon />}
                    />
                  )}
                />
              ));
            }}
          </FieldArray>
          <FieldArray
            name={`${field}.disciplines`}
            aria-label="add-disciplines"
          >
            {({ fields: disciplinesFields }) => {
              const currentDisciplines = disciplinesFields.value;
              return disciplinesFields.map((val, index) => (
                <Field
                  name={val}
                  key={currentDisciplines[index].id}
                  onDelete={() => disciplinesFields.remove(index)}
                  render={props => (
                    <TagChip
                      name={props.input.name}
                      label={currentDisciplines[index].name}
                      tagType="Disciplines"
                      onDelete={props.onDelete}
                      deleteIcon={<ClearIcon />}
                    />
                  )}
                />
              ));
            }}
          </FieldArray>
        </>
      ),
    }));
  };

  return {
    addTagsToSelectedParameters,
    formatHeaders,
    tags,
    setTags,
    disciplines,
    setDisciplines,
    allTags,
    allDisciplines,
    formatFields,
  };
};
