import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Link, Grid } from '@mui/material';
import config from 'config';
import { StyledStraplineTypography } from './styles';

const ProjectsLink = forwardRef((props, ref) => (
  <Link to="/projects" ref={ref} {...props} component={RouterLink} />
));

ProjectsLink.displayName = 'ProjectsLink';

export default function Description() {
  const { t } = useTranslation(['home']);
  const descriptions = {
    development: t('home:descriptions:development'),
    ut: t('home:descriptions:ut'),
    production: t('home:descriptions:production'),
  };

  return (
    <>
      <StyledStraplineTypography variant="h5" component="p" color="inherit">
        {t('home:strapline')}
      </StyledStraplineTypography>
      <Typography
        variant="body6"
        component="p"
        color="inherit"
        mt={1}
        sx={{ fontSize: 15 }}
      >
        {descriptions[config.env]}
      </Typography>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            component={ProjectsLink}
            sx={{ marginTop: 4 }}
            variant="outlined"
            data-cy="go-to-projects"
            color="inherit"
          >
            {t('home:goToProjects')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
