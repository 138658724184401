import {
  Grid,
  Typography,
  Box,
  Chip,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { Error, Done } from '@mui/icons-material';
import { capitalize, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { lighten, darken } from '@mui/material/styles';
import PT from 'prop-types';

const AssetRequirementsCard = ({
  formattedAssets,
  updateSelection,
  requiredAssetsSelected,
  prefixCounter,
}) => {
  const { t } = useTranslation(['templates', 'common']);

  const prefix = String.fromCharCode(97 + prefixCounter);
  const completedSelection = (selectedAssetsArray, assetType) => {
    const matchAssetsWithTypes = selectedAssetsArray.filter(
      selectedAsset => selectedAsset.assetType.id === assetType.assetType.id
    );
    if (
      matchAssetsWithTypes.length === assetType.count ||
      (matchAssetsWithTypes.length > 0 && assetType.count === null)
    ) {
      return true;
    }
    return false;
  };

  const generateChips = (selectedAssetsArray, assetType) => {
    const matchAssetsWithTypes = selectedAssetsArray.filter(
      selectedAsset => selectedAsset.assetType.id === assetType.id
    );

    return matchAssetsWithTypes.map(asset => {
      return (
        <Chip
          aria-label="asset-chip"
          key={asset.id}
          label={asset.name}
          sx={{ marginLeft: 1, marginBottom: 1 }}
          onDelete={
            asset.assetTypeCount === null ? () => updateSelection(asset) : null
          }
        />
      );
    });
  };

  return (
    <Grid item container key={formattedAssets.id}>
      {formattedAssets?.assetType !== null && (
        <Box
          key={formattedAssets.id}
          marginBottom={1}
          sx={{
            backgroundColor: completedSelection(
              requiredAssetsSelected,
              formattedAssets
            )
              ? theme => {
                  if (theme.palette.mode === 'dark')
                    return darken(theme.palette.primary.main, 0.6);
                  return lighten(theme.palette.primary.main, 0.85);
                }
              : theme => theme.palette.background.paper,
          }}
          minWidth="100%"
          aria-label="asset-placeholder-info-box"
        >
          <Grid
            item
            key={formattedAssets.id}
            container
            wrap="nowrap"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography
                marginLeft={formattedAssets.count === 1 ? 1 : 3}
                variant="subtitle2"
                aria-label="asset-placeholder-name"
              >
                {`${prefix}. ${t('common:select')} ${
                  formattedAssets.count !== null
                    ? formattedAssets.count
                    : t('templates:wizard:oneOrMore')
                } - ${
                  !isEmpty(formattedAssets?.assetSubType)
                    ? formattedAssets.assetSubType.name
                    : formattedAssets.name
                }`}
              </Typography>
              <List
                aria-label="asset-placeholder-description"
                key={formattedAssets.id}
                role="list"
              >
                <ListItem
                  sx={{
                    pt: 0,
                    pb: 0,
                    pl: formattedAssets.count === 1 ? 2 : 4,
                  }}
                  key={formattedAssets.id}
                >
                  <ListItemText
                    sx={{ pl: 1 }}
                    primaryTypographyProps={{ variant: 'body2' }}
                    secondaryTypographyProps={{
                      variant: 'body2',
                      textTransform: 'capitalize',
                      color: theme => theme.palette.black,
                    }}
                    primary={
                      <>
                        <Typography
                          variant="body2"
                          component="span"
                          fontWeight="medium"
                          aria-label="placeholder-description-bold"
                        >
                          {`${capitalize(t('common:description'))}: `}
                        </Typography>
                        {formattedAssets.description}
                      </>
                    }
                    secondary={
                      <>
                        <Typography
                          variant="body2"
                          component="span"
                          fontWeight="medium"
                          aria-label="asset-type-name-bold"
                        >
                          {`${capitalize(t('common:assetType'))}: `}
                        </Typography>
                        {formattedAssets.assetType.name}
                        {formattedAssets.assetSubType?.name
                          ? ` (${formattedAssets.assetSubType.name})`
                          : ''}
                      </>
                    }
                  />
                </ListItem>
              </List>
              <Grid
                item
                sx={{ marginLeft: formattedAssets.count === 1 ? 1 : 3 }}
              >
                {generateChips(
                  requiredAssetsSelected,
                  formattedAssets.assetType
                )}
              </Grid>
            </Grid>
            <Grid item>
              {completedSelection(requiredAssetsSelected, formattedAssets) ? (
                <Done
                  aria-label="complete-asset-selection"
                  fontSize="small"
                  sx={{
                    marginTop: 1,
                    marginRight: 1,
                    color: theme => theme.palette.success.main,
                  }}
                />
              ) : (
                <Error
                  aria-label="incomplete-asset-selection"
                  fontSize="small"
                  sx={{
                    marginTop: 1,
                    marginRight: 1,
                    color: theme => theme.palette.grey[600],
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      )}
      {!isEmpty(formattedAssets?.children) &&
        formattedAssets.children.map(child => (
          <AssetRequirementsCard
            key={child.id}
            formattedAssets={child}
            updateSelection={updateSelection}
            requiredAssetsSelected={requiredAssetsSelected}
            prefixCounter={prefixCounter + 1}
          />
        ))}
    </Grid>
  );
};

AssetRequirementsCard.propTypes = {
  formattedAssets: PT.shape({
    id: PT.string,
    name: PT.string,
    defaultName: PT.string,
    count: PT.number,
    children: PT.arrayOf(PT.shape({})),
    description: PT.string,
    assetType: PT.shape({ id: PT.string, name: PT.string }),
    assetSubType: PT.shape({ id: PT.string, name: PT.string }),
  }),
  requiredAssetsSelected: PT.arrayOf(PT.shape({})),
  updateSelection: PT.func,
  prefixCounter: PT.number,
};

AssetRequirementsCard.defaultProps = {
  formattedAssets: {},
  requiredAssetsSelected: [],
  updateSelection: () => {},
  prefixCounter: 0,
};

export default AssetRequirementsCard;
