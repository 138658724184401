import { useState, useEffect, useContext } from 'react';
import { useImmer } from 'use-immer';
import { useParams } from 'react-router-dom';
import { handleApiError, parametersApi } from 'api';
import { uniqBy, isEmpty } from 'lodash';
import { AssetContext } from 'context';

export default (setFormAttribute, push, isAssetLoading, open, values) => {
  const [isLoading, setIsLoading] = useState(true);
  const [allItemTypes, setAllItemTypes] = useImmer([]);
  const { projectId } = useParams();
  const { theAsset } = useContext(AssetContext);
  const [openImport, setOpenImport] = useState(false);
  const [error, setError] = useState(undefined);
  const [valueOptions, setValueOptions] = useImmer([]);
  const [optionsLoading, setOptionsLoading] = useState(false);

  useEffect(() => {
    const getItems = async options => {
      const query = {
        sort_by: 'parameter_type_name',
        order: 'asc',
        page_limit: 500,
      };
      if (options.after) query.after = options.after;
      const response = await parametersApi('getAllItemTypes', query).catch(
        err => handleApiError(err, [])
      );
      if (response) {
        const { itemTypes, paging } = response;
        if (itemTypes) {
          const items = itemTypes
            .map(item => {
              return {
                id: item.id,
                parameterTypeId: item.parameterType.id,
                parameterTypeName: item.parameterType.name,
                assetTypeId: item.assetType?.id,
                assetTypeName: item.assetType?.name,
                assetSubTypeId: item.assetSubType?.id || null,
                assetSubTypeName: item.assetSubType?.name || null,
              };
            })
            .reduce((acc, item) => {
              const index = acc.findIndex(x => x.id === item.parameterTypeId);
              if (index === -1) {
                acc.push({
                  id: item.parameterTypeId,
                  parameterTypeName: item.parameterTypeName,
                  assetTypeId: item.assetTypeId ? [item.assetTypeId] : [],
                  assetTypeName: item.assetTypeName ? [item.assetTypeName] : [],
                  assetSubTypeId: item.assetSubTypeId
                    ? [item.assetSubTypeId]
                    : [],
                  assetSubTypeName: item.assetSubTypeName
                    ? [item.assetSubTypeName]
                    : [],
                });
                return acc;
              }
              if (
                item.assetTypeId !== null &&
                !acc[index].assetTypeId.includes(item.assetTypeId)
              )
                acc[index].assetTypeId.push(item.assetTypeId);
              if (
                item.assetTypeName !== null &&
                !acc[index].assetTypeName.includes(item.assetTypeName)
              )
                acc[index].assetTypeName.push(item.assetTypeName);
              if (
                item.assetSubTypeId !== null &&
                !acc[index].assetSubTypeId.includes(item.assetSubTypeId)
              )
                acc[index].assetSubTypeId.push(item.assetSubTypeId);
              if (
                item.assetSubTypeName !== null &&
                !acc[index].assetSubTypeName.includes(item.assetSubTypeName)
              )
                acc[index].assetSubTypeName.push(item.assetSubTypeName);
              return acc;
            }, [])
            .filter(item => {
              if (theAsset?.assetSubType?.id) {
                return !item.assetSubTypeId.includes(theAsset.assetSubType.id);
              }
              if (theAsset?.assetType) {
                return !item.assetTypeId.includes(theAsset.assetType.id);
              }
              return !isEmpty(item.assetTypeId);
            });
          setAllItemTypes(curr => uniqBy([...curr, ...items], 'id'));
          if (paging?.cursors?.after) getItems({ after: paging.cursors.after });
        }
        setIsLoading(false);
      }
    };
    if (!isAssetLoading && open) {
      getItems({});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, theAsset, isAssetLoading, open]);

  const getParamType = async parameterTypeId => {
    setError(undefined);

    try {
      const paramResponse = await parametersApi('getParameterType', {
        parameter_type_id: parameterTypeId,
      });
      if (paramResponse) {
        const { parameterType } = paramResponse;
        push('combinedParams', {
          parameterTypeId: parameterType.id,
          dataType: parameterType.dataType,
          status: 'unanswered',
          value: null,
          unitTypeId: parameterType.unitType?.id || null,
          unit: null,
          name: parameterType.name,
          source: null,
          tags: [],
          disciplines: [],
          checked: true,
          assetTypeName: theAsset.assetType?.name,
        });
      }
    } catch (err) {
      setOpenImport(false);
      setError(err);
      setFormAttribute('itemsImport', []);
    }
  };

  const itemForImport = values?.itemsImport;

  useEffect(() => {
    const getAllOptions = async () => {
      setOptionsLoading(true);
      const query = {
        parameter_type_id: itemForImport[itemForImport.length - 1]?.id,
      };
      const itemOptions = [];
      const allOptions = await parametersApi('getAllItemTypes', query).catch(
        handleApiError
      );
      if (allOptions) {
        const { itemTypes } = allOptions;
        const itemTypesWithOptions = itemTypes.filter(
          itemType => itemType.hasOptions === true
        );
        if (itemTypesWithOptions.length !== 0) {
          const optionPromises = await Promise.all(
            itemTypesWithOptions.map(async itemType => {
              const { options } = await parametersApi('getOptions', {
                item_type_id: itemType.id,
              });
              return options;
            })
          );
          optionPromises.forEach(options => {
            options.forEach(option => {
              const existingOption = itemOptions.find(
                // eslint-disable-next-line max-nested-callbacks
                item => item.value === option.value
              );
              if (!existingOption) {
                itemOptions.push(option);
              }
            });
          });
        }
        setValueOptions(itemOptions);
        setOptionsLoading(false);
      }
    };
    if (values?.itemsImport) {
      setValueOptions([]);
      getAllOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemForImport]);

  return {
    isLoading,
    getParamType,
    allItemTypes,
    openImport,
    setOpenImport,
    error,
    valueOptions,
    optionsLoading,
  };
};
