import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import PT from 'prop-types';
import { ChevronRight } from '@mui/icons-material';
import AuditAccordionHeader from '../audit-accordion-header/audit-accordion-header';
import AuditAccordionDetails from '../audit-accordion-details/audit-accordion-details';
import historyPropTypes from '../history-content/prop-types/prop-types';

const AuditItemAccordion = ({ history, isLoaded }) => {
  return (
    <Accordion
      disableGutters
      elevation={0}
      square
      sx={{
        boxShadow: 'none',
        borderTop: theme => `1px solid ${theme.palette.divider}`,
        '&::before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ChevronRight />}
        aria-label="accordion-summary"
        sx={{
          flexDirection: 'row-reverse',
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
        }}
      >
        <AuditAccordionHeader isLoaded={isLoaded} history={history} />
      </AccordionSummary>
      <AccordionDetails aria-label="accordion-details">
        <AuditAccordionDetails history={history} />
      </AccordionDetails>
    </Accordion>
  );
};

AuditItemAccordion.propTypes = {
  isLoaded: PT.bool.isRequired,
  ...historyPropTypes,
};

export default AuditItemAccordion;
