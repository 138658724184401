import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { uniqBy, isEmpty } from 'lodash';
import { templateApi, parametersApi } from 'api';
import axios from 'axios';
import { DataTemplatesContext } from 'context';

export default () => {
  const { dataTemplateId } = useParams();
  const {
    dataTemplate,
    templateAssets,
    loadingAssets,
    assetNodes,
    setAssetNodes,
  } = useContext(DataTemplatesContext);
  const [openSummary, setOpenSummary] = useState(true);
  const [templateParameters, setTemplateParameters] = useState([]);
  const [loadingParameters, setLoadingParameters] = useState(true);
  const [formattedAssets, setFormattedAssets] = useState({});
  const [error, setError] = useState({});
  const [templateParameterTypes, setTemplateParameterTypes] = useState([]);

  const handleOpen = () => setOpenSummary(!openSummary);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const query = {};
    const getTemplateParameters = async after => {
      setLoadingParameters(true);
      query.template_id = dataTemplateId;
      if (after) query.after = after;
      try {
        const response = await templateApi(
          'getParameterPlaceholders',
          query,
          source.token
        );
        if (response) {
          const { parameterPlaceholders, paging } = response;
          setTemplateParameters(curr =>
            uniqBy([...curr, ...parameterPlaceholders], 'id')
          );
          if (paging?.cursors?.after) {
            await getTemplateParameters(paging?.cursors?.after);
          }
        }
        setLoadingParameters(false);
      } catch (err) {
        setError(err.response.data);
        setLoadingParameters(false);
      }
    };

    if (dataTemplateId && !isEmpty(dataTemplate)) {
      setTemplateParameters([]);
      getTemplateParameters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTemplate]);

  useEffect(() => {
    setTemplateParameterTypes([]);
    const source = axios.CancelToken.source();
    const didCancel = false;
    const getParameterTypes = async after => {
      const typeIds = templateParameters.map(parameters => {
        return parameters.parameterTypeId;
      });
      const query = {
        sort_by: 'name',
        order: 'asc',
        parameter_type_id: [...typeIds],
      };
      if (after) query.after = after;
      try {
        const response = await parametersApi(
          'getAllParameterTypes',
          query,
          source.token
        );
        if (response) {
          const { parameterTypes, paging } = response;
          if (!didCancel && parameterTypes) {
            setTemplateParameterTypes(current => [
              ...current,
              ...parameterTypes,
            ]);
            // eslint-disable-next-line max-depth
            if (paging?.cursors?.after) {
              getParameterTypes(paging.cursors.after);
            }
          }
        }
      } catch (err) {
        setError(err.response.data);
      }
    };
    if (!isEmpty(templateParameters) && !loadingParameters) {
      getParameterTypes();
    }
  }, [templateParameters, loadingParameters]);

  useEffect(() => {
    if (!isEmpty(templateAssets)) {
      const assetNodeList = templateAssets.map(asset => asset.id);
      setAssetNodes([...assetNodeList]);

      const parametersWithType =
        !isEmpty(templateParameters) && !isEmpty(templateParameterTypes)
          ? templateParameters.reduce((tot, curr) => {
              const typeWithName = templateParameterTypes.find(
                type => type.id === curr.parameterTypeId
              );
              tot.push({ ...curr, parameterTypeName: typeWithName?.name });
              return tot;
            }, [])
          : [];

      const formatNestedAssets = (assetList, parentId = null) => {
        return assetList.reduce((total, curr) => {
          if (curr.parentAssetPlaceholderId === parentId) {
            const obj = { ...curr };
            if (
              !isEmpty(templateParameters) &&
              !isEmpty(templateParameterTypes)
            ) {
              const filteredParams = parametersWithType.filter(
                parameter => parameter.assetPlaceholderId === curr.id
              );
              obj.parameters = [...filteredParams];
            }
            const children = formatNestedAssets(assetList, curr.id);
            if (children.length) obj.children = children;
            total.push(obj);
          }
          return total;
        }, []);
      };
      const nestedAssets = formatNestedAssets(templateAssets, null);
      setFormattedAssets({ ...nestedAssets[0] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateAssets, templateParameters, templateParameterTypes]);

  return {
    openSummary,
    handleOpen,
    loadingAssets,
    loadingParameters,
    formattedAssets,
    templateParameters,
    setError,
    error,
    assetNodes,
    setAssetNodes,
  };
};
