import { useContext } from 'react';
import { HistoryContext } from 'context';
import Container from './history-page-styles';
import AuditItemAccordion from '../audit-item-accordion/audit-item-accordion';

const HistoryContent = () => {
  const { allHistories, isLoaded } = useContext(HistoryContext);

  return (
    <Container aria-label="history-container">
      {allHistories.map(history => {
        return (
          <AuditItemAccordion
            key={history.updatedAt}
            history={history}
            isLoaded={isLoaded}
          />
        );
      })}
    </Container>
  );
};

export default HistoryContent;
