export default [
  {
    id: '1e08965a-f116-4a6d-8bba-74290edc8c0a',
    name: 'Calculation',
    tags: [],
  },
  {
    id: 'f18d3070-dae5-4386-996e-9c70d796e558',
    name: 'Parameter Category',
    tags: [],
  },
  {
    id: 'ca3778f0-9a88-4c3e-8cf8-9c08ba986e31',
    name: 'Client',
    tags: [],
  },
  {
    id: 'de4c4637-cc12-4992-a524-75651d707e43',
    name: 'Workstage',
    tags: [],
  },
  {
    id: 'f0767fd7-15de-4eff-825c-9942c97b9201',
    name: 'Sector',
    tags: [],
  },
  {
    id: '3ea4cab9-43d0-438e-97da-6cc4303490ba',
    name: 'Scope',
    tags: [],
  },
  {
    id: '17bd1c6a-a14c-43ef-a981-192b539af5da',
    name: 'Report',
    tags: [],
  },
];
