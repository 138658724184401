import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import StatusCircle from 'components/status-circle';
import BasicTable from 'components/tables/table';
import { formatValueUnit } from 'utils';
import TagChip from 'components/chips/tag-chip';

const Summary = ({ values }) => {
  const { t, i18n } = useTranslation(['common', 'parameters']);

  const headers = [
    {
      key: 'status',
      label: t('common:dataDrawer.status.title'),
      paddingLeft: 10,
      width: 50,
    },
    {
      key: 'parameter',
      label: t('common:parameterType'),
      width: 200,
    },
    {
      key: 'value',
      label: t('parameters:value'),
      paddingLeft: 0,
      width: 200,
    },
    {
      key: 'tags',
      label: t('parameters:tags'),
      paddingLeft: 0,
      width: 200,
    },
    {
      key: 'source',
      label: t('parameters:source'),
      paddingLeft: 0,
    },
  ];

  const data = values.combinedParams.map(parameter => ({
    key: parameter?.id
      ? `row-${parameter.id}`
      : `row-${parameter.parameterTypeId}-forImport`,
    status: (
      <StatusCircle
        status={
          parameter?.value || parameter?.value === false
            ? 'answered'
            : parameter?.status
        }
      />
    ),
    assetTypeName: parameter?.assetTypeName,
    parameter: parameter.name ? parameter.name : parameter.parameterTypeName,
    value:
      (parameter.value || parameter.value === false) &&
      formatValueUnit(
        typeof parameter.value === 'boolean'
          ? parameter.value.toString()
          : parameter.value,
        parameter?.unit?.symbol,
        i18n.language
      ),
    tags: [
      parameter.tags.map(tag => {
        return (
          <TagChip
            key={
              parameter.id
                ? `${tag.name}-${parameter.id}`
                : `${tag.name}-${parameter.parameterTypeId}`
            }
            tooltip={tag.name}
            tagType={tag.tagType?.name}
          />
        );
      }),
      parameter.disciplines.map(discipline => {
        return (
          <TagChip
            key={
              parameter.id
                ? `${discipline.name}-${parameter.id}`
                : `${discipline.name}-${parameter.parameterTypeId}`
            }
            tooltip={discipline.name}
            tagType="Disciplines"
            aria-label="disciplines"
          />
        );
      }),
    ],
    source: parameter.source?.title,
  }));

  return (
    <BasicTable
      aria-label="summary-table"
      headers={headers}
      data={data}
      dense
      skippingHeaders={['status']}
    />
  );
};

Summary.propTypes = {
  values: PT.shape({
    parameterTypes: PT.arrayOf(
      PT.shape({
        status: PT.string.isRequired,
        name: PT.string.isRequired,
      })
    ),
    combinedParams: PT.arrayOf(
      PT.shape({
        parameterTypeName: PT.string.isRequired,
      })
    ),
  }).isRequired,
};

export default Summary;
