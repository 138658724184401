import React, { useState, useEffect } from 'react';
import PT from 'prop-types';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { auditApi, parametersApi, handleApiError } from 'api';

export const HistoryContext = React.createContext();

export const HistoryProvider = ({ children }) => {
  const [allHistories, setAllHistories] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isHistoryLoaded, setIsHistoryLoaded] = useState(false);
  const [allParameters, setAllParameters] = useState([]);
  const [areParametersLoaded, setAreParametersLoaded] = useState(false);

  const { projectId } = useParams();

  useEffect(() => {
    setIsHistoryLoaded(false);
    let didCancel = false;
    const source = axios.CancelToken.source();

    const getHistory = async after => {
      const query = {
        project_id: projectId,
      };

      if (after) query.after = after;
      const response = await auditApi('getHistory', query, source.token).catch(
        handleApiError
      );
      if (!didCancel && response) {
        const {
          snapshots,
          // paging
        } = response;

        setAllHistories(curr => [...curr, ...snapshots]);

        // if (paging?.cursors?.after) {
        //   await getHistory(paging?.cursors?.after);
        // }
        setIsHistoryLoaded(true);
      }
      return () => {
        didCancel = true;
        source.cancel();
      };
    };

    getHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    setAreParametersLoaded(false);
    const source = axios.CancelToken.source();
    let didCancel = false;

    const getParameters = async (newParameterIds, after) => {
      const query = {
        project_id: projectId,
        parameter_id: newParameterIds,
        show_deleted: true,
      };
      if (after) query.after = after;
      const response = await parametersApi(
        'getAllParameters',
        query,
        source.token
      ).catch(handleApiError);

      if (!didCancel && response) {
        const { parameters: parametersList, paging } = response;

        setAllParameters(curr => [...curr, ...parametersList]);

        if (paging?.cursors?.after) {
          await getParameters(newParameterIds, paging?.cursors?.after);
        }
        setAreParametersLoaded(true);
      }
    };
    if (isHistoryLoaded) {
      setAllParameters([]);

      const newParameterIds = Array.from(
        new Set(allHistories.map(historyItem => historyItem.parameterId))
      );

      getParameters(newParameterIds);
    }
    return () => {
      didCancel = true;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHistoryLoaded]);

  useEffect(() => {
    setIsLoaded(false);
    if (areParametersLoaded) {
      const historiesWithDetails = allHistories.map(history => {
        const parameter = allParameters.find(
          param => param.id === history.parameterId
        );
        return {
          ...history,
          parameterName: parameter?.parameterType?.name,
          parentName: parameter?.parents[0]?.name,
          parentId: parameter?.parents[0]?.id,
        };
      });
      setAllHistories(historiesWithDetails);
      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areParametersLoaded]);

  return (
    <HistoryContext.Provider value={{ isLoaded, allHistories }}>
      {children}
    </HistoryContext.Provider>
  );
};

HistoryProvider.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
};
