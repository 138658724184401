/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import PT from 'prop-types';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { DataGrid } from 'components/tables';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import qs from 'qs';

export default function TemplatesList({ rows, loading }) {
  const { t } = useTranslation(['common', 'templates']);

  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchString = { ...qs.parse(search, { ignoreQueryPrefix: true }) };

  const templatesColumns = [
    {
      flex: 0.5,
      field: 'name',
      headerName: t('templates:templateName'),
      type: 'string',
      valueGetter: ({ value }) => capitalize(value),
    },
    {
      flex: 0.5,
      field: 'description',
      headerName: t('templates:templateDescription'),
      hideable: false,
      valueGetter: ({ value }) => capitalize(value),
    },
  ];

  return (
    <DataGrid
      sx={{ borderRadius: 0 }}
      loading={loading}
      density="standard"
      disableColumnMenu
      columns={templatesColumns}
      rows={rows}
      rowHeight={32}
      autoHeight
      aria-label="dataTemplatesTable"
      showToolbar={false}
      pageSize={pageSize}
      hideFooterRowCount
      hideFooter
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      noRowsMessage="data-grid:noParameterSets"
      onRowClick={params => {
        const searchParams = `?${createSearchParams({
          ...searchString,
        })}`;

        navigate({
          pathname: `template/${params.row.id}`,
          search: searchParams,
        });
      }}
    />
  );
}

TemplatesList.propTypes = {
  rows: PT.arrayOf(PT.shape({})).isRequired,
  loading: PT.bool.isRequired,
};
