import { useEffect, useState, useContext } from 'react';
import { isEmpty, uniq } from 'lodash';
import { DataTemplatesContext } from 'context';

export default (assetNodes, selectedAssets) => {
  const { templateAssets } = useContext(DataTemplatesContext);
  const [requiredAssetsSelected, setRequiredAssetsSelected] = useState([]);
  const [requirementsCount, setRequirementsCount] = useState(0);

  useEffect(() => {
    const filterAssetsForRequirements = assetNode => {
      if (!isEmpty(selectedAssets)) {
        return assetNode?.children.reduce((acc, assetChild) => {
          if (!isEmpty(assetChild.children)) {
            acc.push(...filterAssetsForRequirements(assetChild));
          }
          if (
            selectedAssets.find(
              selectedAsset => selectedAsset.id === assetChild.id
            )
          ) {
            acc.push(assetChild);
          }
          return acc;
        }, []);
      }
      return [];
    };

    const selected = filterAssetsForRequirements(assetNodes[0]);
    setRequiredAssetsSelected(curr => uniq([...curr, ...selected]));
  }, [assetNodes, selectedAssets]);

  const requiredCount = assetsArr => {
    let count = 0;
    assetsArr?.forEach(assetType => {
      if (
        assetType?.assetType !== null &&
        (assetType.count === null || assetType.count === 1)
      ) {
        count += 1;
      }
    });
    return count;
  };

  useEffect(() => {
    const result = requiredCount(templateAssets);
    setRequirementsCount(result);
    templateAssets.forEach(templateAsset => {
      const matchAssetsWithTypes = requiredAssetsSelected.filter(
        selectedAsset =>
          selectedAsset.assetType.id === templateAsset?.assetType?.id
      );
      if (
        matchAssetsWithTypes.length === 1 &&
        templateAsset.count === 1 &&
        matchAssetsWithTypes[0].assetTypeCount === 1
      ) {
        setRequirementsCount(prevCount => {
          return prevCount - 1;
        });
      } else if (
        matchAssetsWithTypes.length >= 1 &&
        templateAsset.count === null &&
        matchAssetsWithTypes[0].assetTypeCount === null
      ) {
        setRequirementsCount(prevCount => {
          return prevCount - 1;
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredAssetsSelected]);

  return {
    requirementsCount,
    requiredAssetsSelected,
  };
};
