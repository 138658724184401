import { Box } from '@mui/material';
import ParameterAccordionDetails from './event-type-details/parameter-accordion-details';
import AssetAccordionDetails from './event-type-details/asset-accordion-details';
import SourceAccordionDetails from './event-type-details/source-accordion-details';
import historyPropTypes from '../history-content/prop-types/prop-types';

const AuditAccordionDetails = ({ history }) => {
  return (
    <Box aria-label="accordion-details-container">
      {history.eventType.startsWith('parameter.event') && (
        <ParameterAccordionDetails history={history} />
      )}
      {history.eventType.startsWith('asset.event') && <AssetAccordionDetails />}
      {history.eventType.startsWith('source.event') && (
        <SourceAccordionDetails />
      )}
    </Box>
  );
};

AuditAccordionDetails.propTypes = historyPropTypes;

export default AuditAccordionDetails;
