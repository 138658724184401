/* eslint-disable no-param-reassign */
import { useState } from 'react';
import { findIndex, isEmpty, uniqWith } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import validateAssetName from 'utils/validate-asset-name';

export default (
  assetNodes,
  formattedAssets,
  setExpanded,
  selectedAssets,
  setSelectedAssets,
  allAssets,
  setAllAssets
) => {
  const [textFieldError, setTextFieldError] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState({});

  const createNewAssets = (newTemplateAssets, parentId, newAssets) => {
    const createAsset = (templateAsset, assetNumber) => {
      const newOne = {
        id: uuidv4(),
        name: `${
          !isEmpty(templateAsset.assetSubType)
            ? `${templateAsset.assetSubType.name}`
            : `${templateAsset.defaultName} ${assetNumber}`
        }`,
        new: true,
        assetType: templateAsset.assetType,
        assetSubType: templateAsset.assetSubType,
        parent: parentId || null,
        children: [],
        deletedAt: null,
      };

      newAssets.push(newOne);
      const parentIndex = findIndex(newAssets, asset => asset.id === parentId);
      if (parentIndex !== -1) {
        newAssets[parentIndex].children.push(newOne.id);
      }
      if (!isEmpty(templateAsset.children)) {
        createNewAssets(templateAsset.children, newOne.id, newAssets);
      }
    };

    newTemplateAssets.forEach(templateAsset => {
      if (
        templateAsset.count === null ||
        (templateAsset.count !== null && isEmpty(templateAsset.children))
      ) {
        const assetNumber = templateAsset?.children?.length || 1;
        createAsset(templateAsset, assetNumber);
      } else if (
        templateAsset.count !== null &&
        templateAsset?.children.length
      ) {
        for (let i = 0; i < templateAsset.count; i++) {
          const assetNumber = templateAsset?.children?.length + i;
          createAsset(templateAsset, assetNumber);
        }
      }
    });
  };

  const getChildStructure = (tree, typeId, parentId, newAssets) => {
    if (
      (tree.assetType?.id === typeId || tree.assetTypeId === typeId) &&
      !isEmpty(tree.children)
    ) {
      createNewAssets(tree.children, parentId, newAssets);
    } else if (!isEmpty(tree.children)) {
      tree.children?.forEach(childTree => {
        getChildStructure(childTree, typeId, parentId, newAssets);
      });
    }
  };

  const onAddAssetClick = node => {
    const newAssets = [];
    const assetNumber = node?.children.length + 1;
    const newOne = {
      id: uuidv4(),
      name: `${
        !isEmpty(node.assetSubType)
          ? `${node.assetSubType.name}`
          : `${node.setInfo.defaultName} ${assetNumber}`
      }`,
      new: true,
      assetType: {
        ...node.assetType,
      },
      assetSubType: { ...node?.assetSubType } || null,
      parent: node.parent || null,
      children: [],
      deletedAt: null,
    };
    newAssets.push(newOne);
    getChildStructure(formattedAssets, node.assetType.id, newOne.id, newAssets);
    setAllAssets(curr => [...curr, ...newAssets]);
    const newExpandedNodes = newAssets.map(asset => asset.id);
    setExpanded(curr => [...curr, ...newExpandedNodes, node.id]);
  };

  const onEditClick = assetId => {
    const index = findIndex(allAssets, asset => asset.id === assetId);
    setAllAssets(curr => {
      curr[index].edited = true;
    });
  };

  const onRemoveAssetClick = node => {
    const index = findIndex(allAssets, asset => asset.id === node.id);
    setAllAssets(curr => {
      curr.splice(index, 1);
    });
  };

  const onClearClick = assetId => {
    const index = findIndex(allAssets, asset => asset.id === assetId);
    setAllAssets(curr => {
      curr[index].edited = false;
    });
  };

  const onDoneClick = (node, editingValue) => {
    const errors = validateAssetName(
      editingValue,
      node.parent || null,
      node.assetType.id,
      allAssets
    );
    setTextFieldError(errors);
    if (!errors) {
      const index = findIndex(allAssets, asset => asset.id === node.id);
      setAllAssets(curr => {
        curr[index].edited = false;
        curr[index].name = editingValue;
        curr[index].editedName = true;
      });
      const selectedAssetIndex = findIndex(
        selectedAssets,
        asset => asset.id === node.id
      );
      if (selectedAssetIndex > -1) {
        setSelectedAssets(curr => {
          curr[selectedAssetIndex].name = editingValue;
          return [...curr];
        });
      }
    }
  };

  const getChildren = (childAssets, childArray) => {
    if (!isEmpty(childAssets)) {
      childAssets.forEach(asset => {
        if (asset?.isAssetInstance) childArray.push(asset);
        getChildren(asset?.children, childArray);
      });
    }
    return childArray;
  };
  const findParentNode = (assetNodesObj, parentAssetId) => {
    if (assetNodesObj.id === parentAssetId) return assetNodesObj;
    if (!isEmpty(assetNodesObj.children)) {
      const results = assetNodesObj.children.map(child =>
        findParentNode(child, parentAssetId)
      );
      const foundResult = results.find(result => result !== null);
      return foundResult || null;
    }
    return null;
  };

  const getParent = (parentId, parentArray) => {
    if (parentId) {
      const parent = allAssets.find(asset => {
        return asset.id === parentId;
      });

      const parentNode = findParentNode(assetNodes[0], parentId);
      if (parent?.id !== undefined) {
        parentArray.push(parentNode);
      }
      getParent(parent?.parent, parentArray);
    }
    return parentArray;
  };

  const updateSelection = node => {
    const assetIndex = findIndex(selectedAssets, asset => asset.id === node.id);
    if (assetIndex > -1) {
      const removeAssets = getChildren(node.children, [node]);
      setSelectedAssets(curr => {
        const assetIdsToRemove = new Set(removeAssets.map(item => item.id));
        const filteredArray = curr.filter(
          item => !assetIdsToRemove.has(item.id)
        );
        return filteredArray;
      });
    } else {
      const checkAssetTypeAlreadySelected = selectedAssets.filter(
        asset => asset.assetType.id === node.assetType.id
      );
      if (
        checkAssetTypeAlreadySelected.length >= 1 &&
        node.assetTypeCount === 1
      ) {
        const parentNode = findParentNode(
          assetNodes[0],
          checkAssetTypeAlreadySelected[0].id
        );

        const nodeChildrenToRemove = getChildren(parentNode.children, [
          parentNode,
        ]);
        const nodeParentsToRemove = getParent(
          checkAssetTypeAlreadySelected[0].parent,
          [checkAssetTypeAlreadySelected[0]]
        );
        setSelectedAssets(curr => {
          const assetIdsToRemove = new Set(
            nodeChildrenToRemove
              .concat(nodeParentsToRemove)
              .map(item => item.id)
          );
          const filteredArray = curr.filter(item => {
            return !assetIdsToRemove.has(item.id);
          });
          const parents = getParent(node.parent, [node]);

          const updatedSelectedAssets = uniqWith(
            [...filteredArray, ...parents],
            (a, b) => a.id === b.id
          );

          return updatedSelectedAssets;
        });
      } else {
        const parents = getParent(node.parent, [node]);
        const parentsSameAssetType = parents
          .map(parent => {
            const sameAssetTypes = selectedAssets.filter(
              asset =>
                asset.assetType.id === parent.assetType.id &&
                asset.id !== parent.id &&
                asset.assetTypeCount === 1
            );
            const updatedNodesInfo = sameAssetTypes.map(asset => {
              return findParentNode(assetNodes[0], asset.id);
            });
            const childrenOfSameAssetType = updatedNodesInfo.map(
              sameAssetType =>
                getChildren(sameAssetType.children, [sameAssetType])
            );

            return childrenOfSameAssetType;
          })
          .flat(2);
        setSelectedAssets(curr => {
          const assetIdsToRemove = new Set(
            parentsSameAssetType.map(item => item.id)
          );

          const filteredArray = curr.filter(item => {
            return !assetIdsToRemove.has(item.id);
          });
          const updatedSelectedAssets = uniqWith(
            [...filteredArray, ...parents],
            (a, b) => a.id === b.id
          );
          return updatedSelectedAssets;
        });
      }
    }
  };

  return {
    updateSelection,
    onAddAssetClick,
    onEditClick,
    onDoneClick,
    onClearClick,
    textFieldError,
    selectedAsset,
    setSelectedAsset,
    onRemoveAssetClick,
  };
};
