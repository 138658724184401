import { Circle } from '@mui/icons-material';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';

const ItemTypesOptions = ({ valueOptions }) => {
  const { t } = useTranslation(['common', 'parameters']);

  return (
    <Grid
      container
      display="flex"
      flexDirection="column"
      alignItems="center"
      aria-label="item-types-options"
    >
      <Grid
        item
        sx={{
          paddingTop: theme => theme.spacing(1),
          paddingBottom: theme => theme.spacing(1),
        }}
      >
        {t('parameters:importOptions')}
      </Grid>
      <Grid item>
        <List
          sx={{
            // eslint-disable-next-line no-confusing-arrow
            bgcolor: theme =>
              theme.palette.mode === 'dark'
                ? theme.palette.background.inherit
                : theme.palette.background.paper,
            border: 1,
            borderRadius: 2,
            borderColor: 'primary.main',
          }}
        >
          {(valueOptions?.length && valueOptions).map(option => {
            return (
              <ListItem key={option.value}>
                <ListItemIcon sx={{ minWidth: '20px' }}>
                  <Circle
                    color="primary"
                    sx={{
                      fontSize: '0.6rem',
                      // eslint-disable-next-line no-confusing-arrow
                      color: theme =>
                        theme.palette.mode === 'dark'
                          ? theme.palette.secondary.main
                          : theme.palette.text.primary,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={option.value} primary={option.value} />
              </ListItem>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
};

ItemTypesOptions.propTypes = {
  valueOptions: PT.arrayOf(PT.shape({})).isRequired,
};

export default ItemTypesOptions;
