import { Suspense } from 'react';
import { Skeleton, Grid } from '@mui/material';
import { withAppInsights } from 'app/app-insights';
import AppFooter from 'components/footer';
import { FavouritesList } from 'components/favourites';
import config from 'config';
import { StyledContainer, StyledGrid, StyledTypography } from './styles';
import Description from './description';
import RedirectBox from './redirect';

function HomePage() {
  return (
    <>
      <main
        id="main-content"
        tabIndex="-1"
        data-testid="home-page"
        style={{ minHeight: 650 }}
      >
        <StyledContainer maxWidth="lg">
          <StyledGrid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item sm={12} md={6}>
              <div>
                <StyledTypography
                  variant="h3"
                  component="h1"
                  color="inherit"
                  gutterBottom
                  data-cy="dDBHomeText"
                >
                  Digital Design Brief
                  {config.env === 'ut' && ' Sandbox'}
                </StyledTypography>
                <Suspense fallback={<Skeleton height={100} />}>
                  <Description />
                </Suspense>
              </div>
            </Grid>
            <Grid item xs>
              <Suspense fallback={<Skeleton height={200} />}>
                <FavouritesList />
              </Suspense>
            </Grid>
            <Grid item xs={12} md={12}>
              <Suspense fallback={<Skeleton height={200} />}>
                <RedirectBox />
              </Suspense>
            </Grid>
          </StyledGrid>
        </StyledContainer>
      </main>
      <AppFooter />
    </>
  );
}

export default withAppInsights(HomePage, 'HomePage');
