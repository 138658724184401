import { useState, useContext } from 'react';
import PT from 'prop-types';
import { ProjectContext } from 'context';
import {
  Typography,
  IconButton,
  Tooltip,
  Popover,
  Breadcrumbs,
  Chip,
  Stack,
  Link,
  Skeleton,
} from '@mui/material';
import { Person, PersonCard } from 'components/microsoft';
import { useTranslation } from 'react-i18next';
import { formatDateWithTime } from 'utils';
import historyPropTypes from '../history-content/prop-types/prop-types';

const eventTypeLookup = {
  'parameter.event.entry.replaced': {
    title: 'Edited Parameter: ',
  },
  'parameter.event.parameter.created': {
    title: 'Created Parameter: ',
  },
  'parameter.event.entry.revision.updated': {
    title: 'Edited Parameter: ',
  },
  'parameter.event.parameter.removed': {
    title: 'Deleted Parameter: ',
  },
};

const category = 'Parameter';
const color = '#F2E8CD';

const AuditAccordionHeader = ({ isLoaded, history }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { project } = useContext(ProjectContext);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? 'simple-popover' : undefined;

  const { i18n } = useTranslation();

  return (
    <Stack
      sx={{ width: 1 }}
      alignItems="center"
      direction="row"
      aria-label="accordion-header"
      data-testid="accordion-header"
    >
      <Stack direction="row" spacing={2} alignItems="center" sx={{ pl: 2 }}>
        {isLoaded ? (
          <Tooltip title={history.triggeredBy.email}>
            <IconButton
              size="small"
              sx={{ p: 0 }}
              aria-describedby={id}
              onClick={handleClick}
              aria-label="person-icon"
            >
              <Person
                personQuery={history.triggeredBy.email}
                fallbackDetails={{
                  userPrincipalName: history.triggeredBy.email,
                  mail: history.triggeredBy.email,
                }}
              />
            </IconButton>
          </Tooltip>
        ) : (
          <Skeleton variant="circular" width={50} height={50} />
        )}
        <Stack spacing={0.3}>
          {isLoaded ? (
            <Stack direction="row" spacing={2}>
              <Typography sx={{ fontWeight: 'bold' }} aria-label="event-type">
                {eventTypeLookup[history.eventType]?.title}
              </Typography>
              <Link
                underline="hover"
                color="inherit"
                href={
                  history.parentId
                    ? `/projects/${history.projectId}/asset/${history.parentId}/parameters/${history.parameterId}`
                    : `/projects/${history.projectId}/parameters/${history.parameterId}`
                }
                aria-label="parameter-name-link"
                onClick={event => {
                  event.stopPropagation();
                }}
              >
                <Typography
                  color={theme => theme.palette.primary.main}
                  sx={{ fontWeight: 'bold' }}
                >
                  {history.parameterName}
                </Typography>
              </Link>
            </Stack>
          ) : (
            <Skeleton variant="rectangular" width={400} height={20} />
          )}
          {isLoaded ? (
            <Breadcrumbs aria-label="breadcrumb">
              <Typography>...</Typography>
              {history.parentName ? (
                <Typography variant="body2">{history.parentName}</Typography>
              ) : (
                <Typography variant="body2">{project?.shortTitle}</Typography>
              )}
            </Breadcrumbs>
          ) : (
            <Skeleton variant="rectangular" width={300} height={20} />
          )}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        spacing={5}
        alignItems="center"
        style={{ marginLeft: 'auto' }}
      >
        {isLoaded ? (
          <Chip
            label={category}
            aria-label="category-chip"
            sx={{ backgroundColor: color }}
          />
        ) : (
          <Skeleton variant="rectangular" width={100} height={30} />
        )}
        {isLoaded ? (
          <Typography aria-label="updated-at" variant="subtitle2">
            {formatDateWithTime(history.updatedAt, i18n.language)}
          </Typography>
        ) : (
          <Skeleton variant="rectangular" width={100} height={30} />
        )}
      </Stack>
      <Popover
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PersonCard
          isExpanded={false}
          personQuery={history.triggeredBy.email}
        />
      </Popover>
    </Stack>
  );
};

AuditAccordionHeader.propTypes = {
  isLoaded: PT.bool.isRequired,
  ...historyPropTypes,
};

export default AuditAccordionHeader;
