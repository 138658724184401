import PT from 'prop-types';

const historyPropTypes = {
  history: PT.shape({
    triggeredBy: PT.shape({
      email: PT.string.isRequired,
    }).isRequired,
    eventType: PT.string.isRequired,
    updatedAt: PT.string.isRequired,
    projectId: PT.string.isRequired,
    parameterId: PT.string.isRequired,
    parameterName: PT.string,
    parents: PT.oneOfType([
      PT.arrayOf(
        PT.shape({
          id: PT.string.isRequired,
          name: PT.string.isRequired,
        })
      ),
      PT.oneOf([null]),
    ]),
    value: PT.oneOfType([PT.string, PT.number, PT.bool]),
    unit: PT.shape({
      symbol: PT.string,
    }),
    source: PT.shape({
      id: PT.string,
      title: PT.string,
    }),
    comment: PT.shape({
      id: PT.string,
      content: PT.string,
    }),
    parentName: PT.string,
    parentId: PT.string,
    status: PT.string,
  }).isRequired,
};

export default historyPropTypes;
