import { Typography, Box } from '@mui/material';
import historyPropTypes from 'routes/history-page/history-content/prop-types/prop-types';
import { useTranslation } from 'react-i18next';

const ParameterAccordionDetails = ({ history }) => {
  const { t } = useTranslation(['histories', 'common']);

  return (
    <Box aria-label="parameter-accordion-details">
      {history.eventType.endsWith('parameter.created') && (
        <Box
          sx={{ pb: theme => theme.spacing(0.5) }}
          aria-label="parameter-created-details"
        >
          <Typography variant="body2">
            {t('histories:parameterWithoutValue')}
          </Typography>
        </Box>
      )}
      {history.eventType.endsWith('entry.replaced') && (
        <Box
          sx={{ pb: theme => theme.spacing(0.5) }}
          aria-label="entry-replaced-details"
        >
          <Typography variant="body2" sx={{ pb: theme => theme.spacing(0.5) }}>
            {`${t('common:dataDrawer:editForm:value')}: ${history.value}`}
          </Typography>
          {history.unit.symbol && (
            <Typography
              variant="body2"
              sx={{ pb: theme => theme.spacing(0.5) }}
            >
              {`${t('common:dataDrawer:editForm:unit')}: ${
                history.unit.symbol
              }`}
            </Typography>
          )}
          <Typography variant="body2" sx={{ pb: theme => theme.spacing(0.5) }}>
            {`${t('common:source')}: ${history.source.title}`}
          </Typography>
          <Typography
            variant="caption"
            fontWeight="500"
            sx={{ pb: theme => theme.spacing(0.5) }}
          >
            {`${t('common:comment')}: ${
              history.comment.content ? history.comment.content : ''
            }`}
          </Typography>
        </Box>
      )}
      {history.eventType.endsWith('entry.revision.updated') && (
        <Box
          sx={{ pb: theme => theme.spacing(0.5) }}
          aria-label="entry-revision-updated-details"
        >
          <Typography variant="body2" sx={{ pb: theme => theme.spacing(0.5) }}>
            {`${t('histories:qaStatusChange')}: ${history.status}`}
          </Typography>
          <Typography
            variant="caption"
            fontWeight="500"
            sx={{ pb: theme => theme.spacing(0.5) }}
          >
            {`${t('common:comment')}: ${
              history.comment.content ? history.comment.content : ''
            }`}
          </Typography>
        </Box>
      )}
      {history.eventType.endsWith('parameter.removed') && (
        <Box
          sx={{ pb: theme => theme.spacing(0.5) }}
          aria-label="parameter-deleted-details"
        >
          <Typography variant="body2">
            {t('histories:parameterDeleted')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

ParameterAccordionDetails.propTypes = historyPropTypes;

export default ParameterAccordionDetails;
