import { useState, useEffect } from 'react';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Checkbox, Collapse, Typography } from '@mui/material';
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from '@mui/icons-material';
import { ListBoxComponent } from 'components/pickers';
import { Autocomplete } from 'components/pickers/final-form-fields';
import config from 'config';
import { ExpandOrCollapseButton } from 'components/buttons';
import { compareArrays, reorderArray } from 'utils';
import ParameterTypeImporter from '../import-params';
import getTypes from './hooks';

const ParameterSelector = ({ isAssetLoading, open, values, ...rest }) => {
  const { t } = useTranslation(['common', 'parameters']);
  const { push, remove, setFormAttribute } = rest;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const { isLoading, parameterTypes } = getTypes(open, isAssetLoading);
  const [openImportDrawer, setOpenImportDrawer] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleOpen = () => setOpenImportDrawer(!openImportDrawer);

  useEffect(() => {
    if (values.itemsImport?.length > 0) setOpenImportDrawer(true);
  }, [values.itemsImport]);

  useEffect(() => {
    const orderedCombinedParams = reorderArray(values.combinedParams);
    setFormAttribute('combinedParams', orderedCombinedParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.combinedParams]);

  return (
    <>
      <Autocomplete
        name="parameterTypes"
        data-cy="select-parameter-box"
        aria-label="parameter-selector"
        variant="outlined"
        disableCloseOnSelect
        disableListWrap
        required
        value={values.parameterTypes}
        ListboxComponent={ListBoxComponent}
        options={parameterTypes}
        getOptionValue={option => option}
        getOptionLabel={option => option.name}
        isOptionEqualToValue={(option, value) =>
          option.parameterTypeId === value.parameterTypeId
        }
        loading={isLoading}
        multiple
        label={t('parameters:addParameters')}
        ChipProps={{ color: 'primary' }}
        renderOption={(props, option, { selected }) => (
          <li {...props} data-cy="selected-parameter">
            <Checkbox
              data-cy="selected-parameter"
              icon={icon}
              checkedIcon={checkedIcon}
              sx={{
                marginRight: theme => theme.spacing(2),
                backgroundColor: 'primary',
              }}
              checked={selected}
            />
            {option.name}
          </li>
        )}
        onChange={(_event, value, reason) => {
          if (reason === 'selectOption') {
            const addedValue = compareArrays(value, values.parameterTypes);
            push('combinedParams', addedValue);
          }
          if (reason === 'removeOption') {
            const { combinedParams } = values;

            const removedValue = compareArrays(values.parameterTypes, value);
            const indexToRemove = combinedParams.findIndex(
              x => x.id === removedValue.id
            );
            remove('combinedParams', indexToRemove);
          }
        }}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === 'input') setInputValue(newInputValue);
          if (event?.type === 'blur') setInputValue('');
        }}
        inputValue={inputValue}
      />
      {config.featureFlags.isCreateItemEnabled && (
        <>
          <ExpandOrCollapseButton
            open={openImportDrawer}
            onClick={handleOpen}
            disabled={values.itemsImport?.length > 0}
          />
          <Typography
            display="inline"
            color="primary"
            aria-label="not-find-question"
          >
            {t('parameters:cannotFindWhatYouNeed')}
          </Typography>
          <Collapse
            in={values.itemsImport?.length > 0 ? true : openImportDrawer}
          >
            <ParameterTypeImporter
              values={values}
              open={open}
              isAssetLoading={isAssetLoading}
              setOpenImportDrawer={setOpenImportDrawer}
              {...rest}
            />
          </Collapse>
        </>
      )}
    </>
  );
};

ParameterSelector.propTypes = {
  open: PT.bool.isRequired,
  values: PT.shape({
    parameterTypes: PT.arrayOf(PT.shape({})),
    itemsImport: PT.arrayOf(PT.shape({})),
    combinedParams: PT.arrayOf(PT.shape({})),
  }).isRequired,
  isAssetLoading: PT.bool.isRequired,
};

export default ParameterSelector;
