/* eslint-disable no-nested-ternary */
import { useContext } from 'react';
import { Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddButton, StyledIconButton } from 'components/buttons';
import ConfidentialChip from 'components/chips/confidential';
import { FavouritesButton } from 'components/favourites';
import { ProjectContext, ProjectPermissionContext } from 'context';
import FiltersButton from 'components/buttons/icons/filters';
import config from 'config';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import qs from 'qs';
import { useSearchParams } from 'react-router-dom';

export default (
  open,
  handleClick,
  handleAdd,
  disabled,
  filterCount,
  tooltipMessage,
  rest
) => {
  const { writePermission } = useContext(ProjectPermissionContext);
  const { project } = useContext(ProjectContext);
  const [searchParams] = useSearchParams();
  const { ...parsedSearch } = qs.parse(searchParams.toString());
  const { t } = useTranslation(['common', 'parameters', 'templates']);

  const actionButtons = {
    parameters: (
      <Grid
        container
        item
        xs={project?.confidential ? 5 : 4}
        justifyContent="flex-end"
        flexWrap="true"
      >
        <Grid
          item
          display="flex"
          justifyContent="flex-end"
          xs={open ? 12 : true}
          sm={open ? 12 : true}
          md={open ? 12 : true}
          lg={open ? 12 : true}
          xl={open ? 4 : true}
          sx={{ paddingTop: '10px' }}
        >
          {project?.confidential && (
            <Box display="flex" justifyContent="flex-end" alignItems="centre">
              <ConfidentialChip iconOnly />
            </Box>
          )}
        </Grid>
        <Grid
          item
          display="flex"
          justifyContent="flex-end"
          xs={open ? 12 : true}
          sm={open ? 12 : true}
          md={open ? 12 : true}
          lg={open ? 12 : true}
          xl={open ? 4 : true}
          sx={{
            paddingRight: theme => theme.spacing(0.5),
            paddingTop: theme => theme.spacing(0.5),
          }}
        >
          <FavouritesButton project={project} />
        </Grid>
        <Grid
          display="flex"
          justifyContent="flex-end"
          item
          xs={open ? 12 : true}
          sm={open ? 12 : true}
          md={open ? 12 : true}
          lg={open ? 12 : true}
          xl={open ? 4 : true}
        >
          <AddButton
            display="flex"
            justifyContent="flex-end"
            alignItems="centre"
            tooltip={
              writePermission
                ? t('parameters:addAssets')
                : t('common:permission.notAllowed')
            }
            onClick={handleClick}
            disabled={!writePermission}
          />
        </Grid>
      </Grid>
    ),
    templates: config.featureFlags.areTemplatesButtonsEnabled && (
      <Grid container item xs={4} justifyContent="flex-end" flexWrap="true">
        <Grid
          sx={{
            paddingRight: theme => theme.spacing(0.5),
            paddingTop: theme => theme.spacing(0.25),
          }}
          display="flex"
          justifyContent="flex-end"
          item
          xs={open ? 12 : true}
          sm={open ? 12 : true}
          md={open ? 12 : true}
          lg={open ? 12 : true}
          xl={open ? 4 : true}
        >
          <FiltersButton onClick={handleClick} {...rest} disabled />
        </Grid>
        <Grid
          display="flex"
          justifyContent="flex-end"
          item
          xs={open ? 12 : true}
          sm={open ? 12 : true}
          md={open ? 12 : true}
          lg={open ? 12 : true}
          xl={open ? 4 : true}
        >
          <AddButton
            onClick={handleAdd}
            disabled={disabled}
            tooltip={
              disabled
                ? t('common:permission.notAllowed')
                : t('common:buttons.add')
            }
          />
        </Grid>
      </Grid>
    ),
    dataSets: (
      <Grid
        container
        justifyContent={
          config.featureFlags.areTemplateFeaturesEnabled
            ? 'space-between'
            : 'flex-end'
        }
        alignItems="center"
        id="dataSetHeader"
        sx={{
          backgroundColor: theme => theme.palette.action.hover,
        }}
      >
        {config.featureFlags.areTemplateFeaturesEnabled && (
          <Grid item>
            <FiltersButton
              filterCount={filterCount}
              {...rest}
              onClick={handleClick}
              disabled
            />
            <StyledIconButton
              title={t('common:sortDirection')}
              onClick={() => {}}
              aria-label="Sort direction"
              color="default"
              disabled
              icon={
                parsedSearch.order === 'asc' ? (
                  <ArrowUpward data-testid="asc" />
                ) : (
                  <ArrowDownward data-testid="desc" />
                )
              }
            />
          </Grid>
        )}
        <Grid item>
          <AddButton
            onClick={handleAdd}
            disabled={writePermission ? disabled : writePermission}
            tooltip={
              writePermission
                ? tooltipMessage
                : t('common:permission.notAllowed')
            }
          />
        </Grid>
      </Grid>
    ),
  };

  return { actionButtons };
};
