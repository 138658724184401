import { Paper } from '@mui/material';
import { DataGridPremium as MuiDataGrid } from '@mui/x-data-grid-premium';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomNoRowsOverlay from './no-rows-overlay';
import CustomToolbar from './toolbar';

function DataGrid({
  exportName,
  showToolbar,
  loading,
  rows,
  columns,
  disableColumnMenu,
  hideFooterPagination,
  density,
  sortingOrder,
  height,
  autoHeight,
  rowHeight,
  noRowsMessage,
  setSelectedRowId,
  searchToolbar,
  DetailPanelContentComponent,
  ...rest
}) {
  const { t } = useTranslation(['data-grid', 'parameters']);

  const slots = {
    toolbar: showToolbar && CustomToolbar,
    NoRowsOverlay: () => CustomNoRowsOverlay(t(noRowsMessage), loading),
  };
  if (rest?.footer) {
    slots.footer = rest.footer;
  }

  return (
    <Paper
      sx={{
        height: autoHeight ? '100%' : height || undefined,
        width: '100%',
      }}
    >
      <MuiDataGrid
        experimentalFeatures={{ ariaV7: true }}
        rows={rows}
        columns={columns}
        getDetailPanelContent={DetailPanelContentComponent}
        getDetailPanelHeight={() => 'auto'}
        autoHeight={autoHeight}
        loading={loading}
        sortingOrder={sortingOrder}
        slots={{ ...slots }}
        slotProps={{
          toolbar: {
            exportName,
            showQuickFilter: true,
            searchToolbar,
          },
        }}
        disableColumnMenu={disableColumnMenu}
        hideFooterPagination={hideFooterPagination}
        // eslint-disable-next-line no-unneeded-ternary
        getRowHeight={() => (rowHeight ? rowHeight : 'auto')}
        getEstimatedRowHeight={() => 200}
        density={density}
        throttleRowsMs={1000}
        onRowSelectionModelChange={rowId => {
          setSelectedRowId(rowId[0]);
        }}
        keepNonExistentRowsSelected
        {...rest}
        localeText={{
          // List of translation options for future reference:
          // https://github.com/mui/mui-x/blob/HEAD/packages/grid/x-data-grid/src/constants/localeTextConstants.ts
          // Root
          noResultsOverlayLabel: t('data-grid:noResultsOverlayLabel'),
          // toolbar button names
          toolbarColumns: t('parameters:columns'),
          toolbarFilters: t('parameters:filters'),
          toolbarExport: t('parameters:export'),
          // Export options
          toolbarExportCSV: t('data-grid:toolbarExportCSV'),
          toolbarExportPrint: t('data-grid:toolbarExportPrint'),
          // Filters toolbar button text
          toolbarFiltersLabel: t('data-grid:toolbarFiltersLabel'),
          toolbarFiltersTooltipHide: t('data-grid:toolbarFiltersTooltipHide'),
          toolbarFiltersTooltipShow: t('data-grid:toolbarFiltersTooltipShow'),
          // eslint-disable-next-line no-confusing-arrow
          toolbarFiltersTooltipActive: count =>
            count !== 1
              ? `${count} ${t('data-grid:toolbarFiltersTooltipActiveSingle')}`
              : `${count} ${t('data-grid:toolbarFiltersTooltipActivePlural')}`,
          // Columns panel text
          columnsPanelTextFieldLabel: t('data-grid:columnsPanelTextFieldLabel'),
          columnsPanelTextFieldPlaceholder: t(
            'data-grid:columnsPanelTextFieldPlaceholder'
          ),
          columnsPanelShowAllButton: t('data-grid:columnsPanelShowAllButton'),
          columnsPanelHideAllButton: t('data-grid:columnsPanelHideAllButton'),
          // Filter panel text
          filterPanelDeleteIconLabel: t('data-grid:filterPanelDeleteIconLabel'),
          filterPanelOperators: t('data-grid:filterPanelOperators'),
          filterPanelOperatorAnd: t('data-grid:filterPanelOperatorAnd'),
          filterPanelOperatorOr: t('data-grid:filterPanelOperatorOr'),
          filterPanelColumns: t('data-grid:filterPanelColumns'),
          filterPanelInputLabel: t('data-grid:filterPanelInputLabel'),
          filterPanelInputPlaceholder: t(
            'data-grid:filterPanelInputPlaceholder'
          ),
          // Filter operators text
          filterOperatorContains: t('data-grid:filterOperatorContains'),
          filterOperatorEquals: t('data-grid:filterOperatorEquals'),
          filterOperatorStartsWith: t('data-grid:filterOperatorStartsWith'),
          filterOperatorEndsWith: t('data-grid:filterOperatorEndsWith'),
          filterOperatorIsEmpty: t('data-grid:filterOperatorIsEmpty'),
          filterOperatorIsNotEmpty: t('data-grid:filterOperatorIsNotEmpty'),
          filterOperatorIsAnyOf: t('data-grid:filterOperatorIsAnyOf'),
        }}
      />
    </Paper>
  );
}

DataGrid.propTypes = {
  exportName: PT.string,
  height: PT.oneOfType([PT.string, PT.number]),
  showToolbar: PT.bool,
  autoHeight: PT.bool,
  disableColumnMenu: PT.bool,
  hideFooterPagination: PT.bool,
  sortingOrder: PT.arrayOf(PT.string),
  loading: PT.bool.isRequired,
  columns: PT.arrayOf(
    PT.shape({
      field: PT.string.isRequired,
      headerName: PT.string.isRequired,
      type: PT.string,
    })
  ).isRequired,
  rows: PT.arrayOf(
    PT.shape({
      id: PT.string,
      userId: PT.string,
    })
  ).isRequired,
  density: PT.oneOf(['comfortable', 'compact', 'standard']),
  noRowsMessage: PT.string,
  rowHeight: PT.oneOfType([PT.number, PT.string]),
  setSelectedRowId: PT.func,
  searchToolbar: PT.bool,
  DetailPanelContentComponent: PT.func,
};

DataGrid.defaultProps = {
  exportName: '',
  sortingOrder: ['desc', 'asc'],
  showToolbar: true,
  disableColumnMenu: true,
  hideFooterPagination: false,
  density: 'compact',
  height: 300,
  autoHeight: false,
  noRowsMessage: 'data-grid:noRowsLabel',
  rowHeight: 'auto',
  setSelectedRowId: () => {},
  searchToolbar: false,
  DetailPanelContentComponent: undefined,
};

export default DataGrid;
