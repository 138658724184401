/* eslint-disable no-unused-vars */
import PT from 'prop-types';
import { Autocomplete } from 'components/pickers/final-form-fields';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListBoxComponent } from 'components/pickers';
import { Box, Grid, Typography } from '@mui/material';
import { Warning } from '@mui/icons-material';
import Alert from 'components/alerts';
import WarningDialog from 'components/dialogs/warning';
import { AssetContext } from 'context';
import { useTheme } from '@mui/material/styles';
import DictionaryButton from 'components/buttons/icons/dictionary';
import { compareArrays } from 'utils';
import config from '../../../../config';
import ItemTypesOptions from './types-with-options';
import getItemTypes from './hooks';

const ParameterTypeImporter = ({
  values,
  setFormAttribute,
  isAssetLoading,
  open,
  setOpenImportDrawer,
  ...rest
}) => {
  const theme = useTheme();
  const { remove, push } = rest;
  const {
    isLoading,
    allItemTypes,
    openImport,
    setOpenImport,
    getParamType,
    error,
    valueOptions,
    optionsLoading,
  } = getItemTypes(setFormAttribute, push, isAssetLoading, open, values);
  const { theAsset } = useContext(AssetContext);

  const { t, i18n } = useTranslation(['common', 'parameters']);

  const [selectedParamForImport, setSelectedParamForImport] = useState(false);

  const handleAccept = () => {
    setOpenImport(false);
    setOpenImportDrawer(true);
  };

  const handleRequest = () =>
    window.open(config.url.newParameterTypeForm, '_blank');

  return (
    <>
      {error && (
        <Box sx={{ padding: theme.spacing(3) }} aria-label="import-alert">
          <Alert title={error.msg} text={error.details} />
        </Box>
      )}
      <>
        <Grid container marginBottom={theme.spacing(1)}>
          <Grid item>
            <Typography
              sx={{
                color: 'textPrimary',
                marginRight: () => theme.spacing(0.75),
              }}
            >
              {t('parameters:importParameterFromDDB')}
            </Typography>
          </Grid>
          <Grid item>
            <Warning
              sx={{
                color: theme.palette.grey[600],
                marginRight: theme.spacing(0.75),
              }}
            />
          </Grid>
          <Grid item>
            <Typography
              sx={{ color: theme.palette.grey[700] }}
              aria-label="warning-prompt"
            >
              {t('parameters:importWarning')}
            </Typography>
          </Grid>
        </Grid>
        {!openImport && (
          <Autocomplete
            name="itemsImport"
            data-cy="select-item-box"
            aria-label="itemsImport-selector"
            variant="outlined"
            required={false}
            ChipProps={{ color: 'primary' }}
            value={values.itemsImport}
            multiple
            ListboxComponent={ListBoxComponent}
            options={allItemTypes}
            getOptionValue={option => option}
            renderOption={(props, option, { selected }) => (
              <li {...props} data-cy="selected-item">
                {`${option.parameterTypeName} (
              ${option?.assetTypeName?.join(', ')} 
              ${option?.assetSubTypeName?.length ? ', ' : ''}
              ${option?.assetSubTypeName?.join(', ')} )`}
              </li>
            )}
            getOptionLabel={option => {
              return `${option.parameterTypeName} (
              ${option?.assetTypeName?.join(', ')}
              ${option?.assetSubTypeName?.length ? ', ' : ''}
              ${option?.assetSubTypeName?.join(', ')} )`;
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            loading={isLoading}
            onChange={(_event, value, reason) => {
              if (reason === 'selectOption') {
                const addedValue = compareArrays(value, values.itemsImport);
                setOpenImport(true);
                setSelectedParamForImport(true);
                getParamType(addedValue.id);
              }
              if (reason === 'removeOption') {
                setSelectedParamForImport(false);
                const { combinedParams } = values;
                const removedValue = compareArrays(values.itemsImport, value);
                const indexToRemove = combinedParams.findIndex(
                  option => option?.parameterTypeId === removedValue.id
                );
                remove('combinedParams', indexToRemove);
              }
            }}
            label={t('parameters:importParameter')}
          />
        )}
      </>
      {openImport && selectedParamForImport && !optionsLoading && (
        <WarningDialog
          aria-label="import-warning"
          open={!!openImport}
          handleClose={() => {
            setOpenImport(false);
            remove('itemsImport', values.itemsImport?.length - 1);
            remove('combinedParams', values.combinedParams?.length - 1);
          }}
          handleAction={valueOptions.length > 0 ? handleRequest : handleAccept}
          secondaryButton
          title={t(
            valueOptions.length === 0
              ? 'parameters:importItemTitle'
              : 'parameters:importRequestTitle',
            {
              parameterName:
                values.itemsImport[values?.itemsImport?.length - 1]
                  .parameterTypeName,
              assetName: theAsset?.assetType?.name || t('common:projectLevel'),
              interpolation: { escapeValue: false },
            }
          )}
          body={`${t('parameters:importItemParam')} 
                  ${
                    valueOptions.length === 0
                      ? `${t('parameters:importItemConfirm')}`
                      : `${t('parameters:confirmItemRequest')}`
                  }
                        `}
          itemOptions={
            valueOptions.length > 0 && (
              <ItemTypesOptions valueOptions={valueOptions} />
            )
          }
          primaryButtonText={
            valueOptions.length > 0
              ? t('common:buttons.request')
              : t('common:buttons.add')
          }
          secondaryButtonText={t('common:buttons.cancel')}
          icon={<DictionaryButton />}
        />
      )}
    </>
  );
};

ParameterTypeImporter.propTypes = {
  values: PT.shape({
    itemsImport: PT.arrayOf(
      PT.shape({ parameterTypeName: PT.string, id: PT.string })
    ),
    combinedParams: PT.arrayOf(PT.shape({})),
  }).isRequired,
  setFormAttribute: PT.func.isRequired,
  isAssetLoading: PT.bool.isRequired,
  open: PT.bool.isRequired,
  setOpenImportDrawer: PT.func.isRequired,
};

export default ParameterTypeImporter;
