/* eslint-disable max-nested-callbacks */
import { useState, useEffect } from 'react';
import qs from 'qs';
import { useSearchParams, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import config from 'config';

export default () => {
  const [openSwitchMenu, setOpenSwitchMenu] = useState(false);
  const { projectId } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);

  const { t } = useTranslation(['common', 'templates']);
  const [searchParams, setSearchParams] = useSearchParams({
    show_drafts: 'false',
    show_published: 'true',
  });
  const { ...parsedSearch } = qs.parse(searchParams.toString());

  useEffect(() => {
    const { show_published: showPublished, show_drafts: showDrafts } =
      parsedSearch;
    const defaults = { show_published: showPublished, show_drafts: showDrafts };
    defaults.show_published =
      showPublished ?? searchParams.get('show_published');
    defaults.show_drafts = showDrafts ?? searchParams.get('show_drafts');
    setSearchParams(defaults, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const onToggle = (e, type) => {
    const types = {
      draft: 'show_drafts',
      published: 'show_published',
      active: 'show_active',
    };
    searchParams.set(types[type], e.target.checked ? 'true' : 'false');
    setSearchParams(searchParams);
  };

  const filterMenu = [
    {
      switchLabel: t('templates:showDrafts'),
      handleChange: e => {
        onToggle(e, 'draft');
      },
      isChecked: parsedSearch?.show_drafts === 'true',
    },
    {
      switchLabel: t('templates:showPublished'),
      handleChange: e => {
        onToggle(e, 'published');
      },
      isChecked: parsedSearch?.show_published === 'true',
    },
  ];

  if (config.featureFlags.areTemplateFeaturesEnabled) {
    filterMenu.push({
      switchLabel: t('templates:showActiveTemplates'),
      handleChange: e => {
        onToggle(e, 'active');
      },
      isChecked: parsedSearch?.show_active === 'true',
    });
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setOpenSwitchMenu(!openSwitchMenu);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenSwitchMenu(!openSwitchMenu);
  };

  return {
    setOpenSwitchMenu,
    setAnchorEl,
    openSwitchMenu,
    onToggle,
    anchorEl,
    filterMenu,
    handleClick,
    handleClose,
  };
};
