import { Grid, Box, Button, Typography, useMediaQuery } from '@mui/material';
import config from 'config';
import { useTranslation } from 'react-i18next';

const RedirectBox = () => {
  const { t } = useTranslation('home');
  const screenXs = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const redirectBox = {
    development: {
      header: t('home:redirectBox.ut.header'),
      content: t('home:redirectBox.ut.content'),
      button: t('home:redirectBox.ut.button'),
      href: 'https://sandbox.ddb.arup.com/',
    },
    ut: {
      header: t('home:redirectBox.production.header'),
      content: t('home:redirectBox.production.content'),
      button: t('home:redirectBox.production.button'),
      href: 'https://ddb.arup.com/',
    },
    production: {
      header: t('home:redirectBox.ut.header'),
      content: t('home:redirectBox.ut.content'),
      button: t('home:redirectBox.ut.button'),
      href: 'https://sandbox.ddb.arup.com/',
    },
  };

  return (
    <Box border={1} p={2} sx={{ borderRadius: 1 }} aria-label="redirect-box">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item sm={7} xs={12} pr={10}>
          <Typography variant="h5" component="p" color="inherit">
            {redirectBox[config.env].header}
          </Typography>
          <Typography variant="body6">
            {redirectBox[config.env].content}
          </Typography>
        </Grid>
        <Grid
          container
          item
          sm={5}
          xs={12}
          justifyContent={screenXs ? 'right' : 'center'}
        >
          <Button
            variant="outlined"
            color="inherit"
            href={redirectBox[config.env].href}
          >
            {redirectBox[config.env].button}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RedirectBox;
