/* eslint-disable no-param-reassign */
import { useState, useEffect, useContext } from 'react';
import { useImmer } from 'use-immer';
import { isEmpty, orderBy } from 'lodash';
import { formatAssetNavigation } from 'utils/format-asset-navigation';
import { PageLayoutContext, AssetContext } from 'context';

export default () => {
  const { allAssets, isLoading, wizardOpen, setWizardOpen } =
    useContext(AssetContext);
  const { openDrawers, setOpenDrawers } = useContext(PageLayoutContext);
  const [assetNodes, setAssetNodes] = useImmer([]);
  const [isProjectAssetHierarchy, setIsProjectAssetHierarchy] = useState(true);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState([]);

  const handleOpenCloseDrawer = () =>
    setOpenDrawers(curr => {
      curr.openNavigation = !openDrawers.openNavigation;
    });

  const handleOpenCloseWizard = () => setWizardOpen(!wizardOpen);

  const filterMenu = [
    {
      switchLabel: 'parameters:projectAssetHierarchy',
      handleChange: () => {
        setIsProjectAssetHierarchy(!isProjectAssetHierarchy);
      },
      isChecked: isProjectAssetHierarchy,
      disabled: false,
    },
  ];

  useEffect(() => {
    setLoading(true);
    if (!isLoading && !isEmpty(allAssets)) {
      const orderedAssets = orderBy(
        allAssets,
        ['deletedAt', asset => asset.name.toLowerCase()],
        ['desc', 'asc']
      );
      const hierarchy = formatAssetNavigation(orderedAssets, null);
      setAssetNodes(hierarchy);
      setLoading(false);
    } else if (!isLoading && isEmpty(allAssets)) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, allAssets]);

  return {
    assetNodes,
    isProjectAssetHierarchy,
    setIsProjectAssetHierarchy,
    filterMenu,
    handleOpenCloseDrawer,
    handleOpenCloseWizard,
    openDrawers,
    expanded,
    setExpanded,
    loading,
  };
};
