import { Typography, Box } from '@mui/material';

const SourceAccordionDetails = () => {
  return (
    <Box aria-label="source-accordion-details">
      <Typography>Source Accordion Details</Typography>
    </Box>
  );
};

export default SourceAccordionDetails;
