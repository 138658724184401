import React, { useState, useEffect } from 'react';
import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import axios from 'axios';
import { useImmer } from 'use-immer';
import PT from 'prop-types';
import { uniqBy, isEmpty } from 'lodash';
import { templateApi } from 'api';
import qs from 'qs';

export const DataTemplatesContext = React.createContext();

export const DataTemplatesProvider = ({ children }) => {
  const { dataTemplateId } = useParams();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [dataTemplates, setDataTemplates] = useImmer([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true });
  const [dataTemplate, setDataTemplate] = useState({});
  const [templateAssets, setTemplateAssets] = useState([]);
  const [loadingAssets, setLoadingAssets] = useState(true);
  const query = { ...parsedSearch };
  const [openTemplateWizard, setOpenTemplateWizard] = useState(false);
  const [assetNodes, setAssetNodes] = useState([]);

  const handleOpenCloseWizard = () => {
    setOpenTemplateWizard(!openTemplateWizard);
  };

  useEffect(() => {
    setAssetNodes([]);
    const source = axios.CancelToken.source();
    const getDataTemplates = async after => {
      setLoading(true);
      if (after) query.after = after;
      try {
        const response = await templateApi('getTemplates', query, source.token);
        if (response) {
          const { templates, paging } = response;
          setDataTemplates(currentDataTemplates =>
            uniqBy([...currentDataTemplates, ...templates], 'id')
          );
          if (paging?.cursors?.after) {
            await getDataTemplates(paging?.cursors?.after);
          }
        }
        setLoading(false);
      } catch (err) {
        if (err.response?.status === 503)
          navigate('/error', {
            replace: true,
            state: {
              status: err.response.status,
              ...err.response.data,
            },
          });
        else {
          setLoading(false);
          setError(err.response?.data);
        }
      }
    };

    if (
      (pathname.includes('/templates') && search) ||
      (isEmpty(dataTemplates) && dataTemplateId) ||
      (isEmpty(dataTemplates) && pathname.includes('/templates'))
    ) {
      setDataTemplates([]);
      setAssetNodes([]);
      getDataTemplates(source);
    }

    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    const getTemplate = () => {
      const filteredParameterSetTemplate = dataTemplates.filter(
        setType => setType.id === dataTemplateId
      );
      setDataTemplate(...filteredParameterSetTemplate);
    };

    if (!loading && (dataTemplateId || pathname.includes(dataTemplateId))) {
      getTemplate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTemplateId, loading]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getTemplateAssets = async after => {
      setLoadingAssets(true);
      query.template_id = dataTemplateId;
      if (after) query.after = after;
      try {
        const response = await templateApi(
          'getAssetPlaceholders',
          query,
          source.token
        );
        if (response) {
          const { assetPlaceholders, paging } = response;
          setTemplateAssets(curr =>
            uniqBy([...curr, ...assetPlaceholders], 'id')
          );
          if (paging?.cursors?.after) {
            await getTemplateAssets(paging?.cursors?.after);
          }
        }
        setLoadingAssets(false);
      } catch (err) {
        setError(err?.response?.data);
        setLoadingAssets(false);
      }
    };

    if (dataTemplateId && !isEmpty(dataTemplate)) {
      setTemplateAssets([]);
      setAssetNodes([]);
      getTemplateAssets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTemplate]);

  return (
    <DataTemplatesContext.Provider
      value={{
        dataTemplates,
        setDataTemplates,
        loading,
        error,
        dataTemplate,
        query,
        openTemplateWizard,
        setOpenTemplateWizard,
        handleOpenCloseWizard,
        templateAssets,
        loadingAssets,
        assetNodes,
        setAssetNodes,
      }}
    >
      {children}
    </DataTemplatesContext.Provider>
  );
};

DataTemplatesProvider.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
};
