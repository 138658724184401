import { Typography, Box } from '@mui/material';

const AssetAccordionDetails = () => {
  return (
    <Box aria-label="asset-accordion-details">
      <Typography>Asset Accordion Details</Typography>
    </Box>
  );
};

export default AssetAccordionDetails;
