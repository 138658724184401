import {
  DataSetsContext,
  DataSetsWizardContext,
  DataTemplatesContext,
} from 'context';
import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import useSummary from 'routes/templates/template-details/asset-types/hooks/use-summary';
import CreateDataSet from '../create-data-set';
import AssetsTree from '../assets-tree';
import Instructions from '../instructions';
import DataSetParametersList from '../data-set-parameters-list';

export default toggleOpen => {
  const { t } = useTranslation(['templates']);
  const { assetNodes, templateAssets, selectedAssets } = useContext(
    DataSetsWizardContext
  );
  const { dataTemplate } = useContext(DataTemplatesContext);
  const { setDataSets } = useContext(DataSetsContext);
  const { formattedAssets, templateParameters } = useSummary();

  const [suggestedName, setSuggestedName] = useState('');

  useEffect(() => {
    if (!isEmpty(selectedAssets)) {
      const keyTemplateAsset = templateAssets.find(
        templateAsset => templateAsset.isKey
      );
      const keySelectedAsset = selectedAssets.find(
        selectedAsset =>
          selectedAsset.assetType.id === keyTemplateAsset.assetType.id
      );
      setSuggestedName(`${dataTemplate.name} - ${keySelectedAsset?.name}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssets, templateAssets, dataTemplate]);

  const handleClose = () => {
    toggleOpen();
  };

  const submitDataSet = values => {
    const { dataSet } = values;
    setDataSets(curr => [...dataSet, ...curr]);
    toggleOpen();
  };

  const steps = [
    {
      label: t('templates:wizard.instructions'),
      optional: false,
      header: t('templates:wizard.readTheseInstructions'),
      content: () => <Instructions />,
    },
    {
      label: t('templates:wizard.selectRequiredAssets'),
      optional: false,
      header: '',
      content: props => (
        <AssetsTree
          loading={false}
          assetNodes={assetNodes}
          formattedAssets={formattedAssets}
          {...props}
        />
      ),
    },
    {
      label: t('templates:wizard.confirmParametersToAdd'),
      optional: false,
      header: t('templates:wizard.reviewParameters'),
      content: props => (
        <DataSetParametersList
          formattedAssets={formattedAssets}
          templateParameters={templateParameters}
          {...props}
        />
      ),
    },
    {
      label: t('templates:wizard.addDataSet'),
      optional: false,
      header: t('templates:wizard.addDataSet'),
      content: props => <CreateDataSet {...props} />,
    },
  ];

  return {
    steps,
    handleClose,
    submitDataSet,
    suggestedName,
  };
};
